.frkd-profile-picture-item {
    position: relative;
    border-radius: 50%;
    background-color: #ffb253;
    width: 75px;
    height: 75px;
    z-index: 0;
}

.frkd-profile-picture-icon1 {
    position: absolute;
    margin: 0 !important;
    height: 69.52%;
    width: 66.67%;
    top: 14.67%;
    right: 17.33%;
    bottom: 15.81%;
    left: 16%;
    max-width: 100%;
    overflow: hidden;
    max-height: 100%;
    object-fit: cover;
    z-index: 1;
}

.frkd-profile-picture1 {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    position: relative;
    gap: 10px;
}

.frkd-username1 {
    font-size: 15px;
}

.frkd-username1,
.frkd-userrole1 {
    position: relative;
    display: inline-block;
    width: 116px;
    height: 20px;
    flex-shrink: 0;
}

.frkd-profile-data1 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 4px;
}

.frkd-profile-section1,
.frkd-profile1 {
    display: flex;
    padding: 0 0 0 20px;
    box-sizing: border-box;
}

.frkd-profile1 {
    background-color: #e0e0e0;
    width: 224px;
    height: 108px;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 9px;
}

.frkd-profile-section1 {
    width: 217px;
    height: 136px;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

.frkd-medlog-logo-icon1 {
    position: relative;
    width: 200px;
    height: 97px;
    object-fit: cover;
}

.frkd-header1,
.frkd-logo1 {
    display: flex;
    align-items: flex-start;
}

.frkd-logo1 {
    flex-direction: column;
    padding: 20px 10px 10px;
    justify-content: flex-start;
}

.frkd-header1 {
    position: absolute;
    width: calc(100% - 235px);
    top: 5px;
    right: 3px;
    left: 232px;
    background-color: #fff;
    flex-direction: row;
    justify-content: space-between;
    text-align: left;
    font-size: 14px;
    color: #000;
    font-family: "Verdana";
}

.frkd-menuitem-image-icon3 {
    position: relative;
    width: 28px;
    height: 32px;
    object-fit: cover;
}

.frkd-navigationitem3,
.frkd-schulungen1 {
    display: flex;
    align-items: center;
}

.frkd-schulungen1 {
    flex: 1;
    position: relative;
    font-size: 16px;
    font-weight: 600;
    font-family: "Verdana";
    color: #777;
    text-align: left;
    height: 34px;
}

.frkd-navigationitem3 {
    cursor: pointer;
    border: 1px solid #e0e0e0;
    padding: 0 0 0 10px;
    background-color: transparent;
    border-radius: 5px;
    box-sizing: border-box;
    width: 203px;
    height: 62px;
    flex-direction: row;
    justify-content: center;
}

.frkd-navigation-links1 {
    width: 201px;
    height: 280px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 50px;
}

.frkd-split-stroke1 {
    align-self: stretch;
    position: relative;
    border-top: 2px solid #a6a6a6;
    box-sizing: border-box;
    height: 2px;
}

.frkd-settings-icon1 {
    position: relative;
    width: 26px;
    height: 28.95px;
    overflow: hidden;
    flex-shrink: 0;
}

.frkd-settings-btn1,
.frkd-text2 {
    height: 35.15px;
    display: flex;
    align-items: center;
}

.frkd-text2 {
    position: relative;
    font-size: 14px;
    font-weight: 600;
    font-family: "Verdana";
    color: #fff;
    text-align: left;
    width: 114px;
    flex-shrink: 0;
}

.frkd-settings-btn1 {
    cursor: pointer;
    border: 0;
    padding: 0;
    background-color: transparent;
    width: 158px;
    flex-direction: row;
    justify-content: flex-start;
    gap: 18px;
}

.frkd-logout-icon1 {
    position: relative;
    width: 28px;
    height: 32.05px;
    overflow: hidden;
    flex-shrink: 0;
}

.frkd-logout-btn1,
.frkd-text3 {
    height: 36.19px;
    display: flex;
    align-items: center;
}

.frkd-text3 {
    position: relative;
    font-size: 14px;
    font-weight: 600;
    font-family: "Verdana";
    color: #fff;
    text-align: left;
    width: 112px;
    flex-shrink: 0;
}

.frkd-logout-btn1 {
    cursor: pointer;
    border: 0;
    padding: 0;
    background-color: transparent;
    width: 158px;
    flex-direction: row;
    justify-content: flex-start;
    gap: 18px;
}

.frkd-settings-logout-section1,
.frkd-sidebar1 {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.frkd-settings-logout-section1 {
    align-self: stretch;
    height: 92px;
    align-items: flex-start;
}

.frkd-sidebar1 {
    margin: 0;
    position: absolute;
    height: 100%;
    top: 0;
    bottom: 0;
    left: -5px;
    background-color: #202123;
    padding: 26px 17px;
    box-sizing: border-box;
    align-items: center;
}

.frkd-modulname {
    flex: 1;
    position: relative;
}

.frkd-info-list-comp,
.frkd-modul-comp {
    align-self: stretch;
    border-radius: 5px;
    background-color: #e9e9e9;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    padding: 17px 16px 16px;
    align-items: flex-start;
    justify-content: flex-start;
}

.frkd-info-list-comp {
    background-color: #737373;
    justify-content: space-between;
}

.frkd-info-list-comp:hover {
    background-color: #616060;
    cursor: pointer;
    /* Hintergrundfarbe beim Darüberfahren */
}

.frkd-schulung-lschen {
    position: relative;
    font-size: 18px;
    font-family: "Verdana";
    color: #fff;
    text-align: center;
}

.frkd-delete-btn {
    cursor: pointer;
    border: 0;
    padding: 12px 24px;
    background-color: #ff7676;
    border-radius: 10px;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.frkd-speichern-btn {
    cursor: pointer;
    border: 0;
    padding: 12px 52px;
    background-color: #424242;
    border-radius: 10px;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    align-self: flex-start;
}


.frkd-gltigkeitsdauer-ndern {
    display: inline-block;
    width: 187px;
    height: 25px;
    flex-shrink: 0;
}

.frkd-gltigkeitsdauer-ndern,
.frkd-name1 {
    position: relative;
    line-height: 20px;
}

.frkd-iconsaxlinearcalendar {
    position: relative;
    width: 24px;
    height: 24px;
    overflow: hidden;
    flex-shrink: 0;
}

.frkd-date2,
.frkd-datepicker {
    display: flex;
    justify-content: flex-start;
}

.frkd-datepicker {
    border-radius: 5px;
    background-color: #e2e1e5;
    border: 1px solid #565657;
    box-sizing: border-box;
    width: 185px;
    height: 51px;
    overflow: hidden;
    flex-shrink: 0;
    flex-direction: row;
    padding: 7px 13px;
    align-items: center;
    gap: 25px;
    font-size: 14px;
    color: #a6a6a6;
}

.frkd-date2 {
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;
}

.frkd-lower-details-section {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
    text-align: left;
    font-size: 16px;
    color: #000;
}

.frkd-bereich-list {
    align-self: stretch;
    flex: 1;
    border: 1px solid #000;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    padding: 20px;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 30px;
    /* adjust this as necessary */
}


.frkd-informationsbereiche {
    position: relative;
}

.frkd-label {
    display: flex;
    flex-direction: row;
    padding: 10px;
    align-items: center;
    justify-content: center;
}

.frkd-hinzufgen {
    position: relative;
    font-size: 16px;
    font-family: "Verdana";
    color: #000;
    text-align: center;
}

.frkd-add-btn {
    cursor: pointer;
    border: 1px solid #ff8d00;
    padding: 9px 11px;
    background-color: #ffba65;
    border-radius: 10px;
    box-sizing: border-box;
    width: 110px;
    overflow: hidden;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
}

.frkd-add-btn,
.frkd-actionsButtons,
.frkd-delete-btn2 {
    display: flex;
    flex-direction: row;
}

.frkd-delete-btn2 {
    cursor: pointer;
    border: 1px solid #ff325b;
    padding: 9px 7px;
    background-color: #f68080;
    border-radius: 10px;
    box-sizing: border-box;
    width: 110px;
    overflow: hidden;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
}

.frkd-actionsButtons {
    align-items: flex-end;
    justify-content: flex-start;
    gap: 62px;
}

.frkd-upper-information-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
}





.frkd-menuitem2-1-child {
    position: relative;
    border-radius: 50%;
    background-color: #fff;
    border: 1px solid #000;
    box-sizing: border-box;
    width: 20px;
    height: 20px;
}

.frkd-grundlagen {
    position: relative;
    font-size: 22px;
    font-weight: 600;
    font-family: "Verdana";
    color: #434343;
    text-align: center;
}

.frkd-grundlagen-wrapper,
.frkd-menuitem2-1 {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.frkd-grundlagen-wrapper {
    border-radius: 5px;
    background-color: #d9d9d9;
    width: 246px;
    overflow: hidden;
    flex-shrink: 0;
    padding: 17px 63px;
    box-sizing: border-box;
    justify-content: center;
}

.frkd-menuitem2-1 {
    cursor: pointer;
    border: 0;
    padding: 0;
    background-color: transparent;
    justify-content: flex-start;
    gap: 34px;
}

.frkd-actions-list,
.frkd-frame-parent,
.frkd-menuitems2 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 32px;
}

.frkd-actions-list,
.frkd-frame-parent {
    align-items: center;
    gap: 10px;
}

.frkd-frame-parent {
    height: 328px;
    border-top: #000 1px solid;
    padding-top: 10px;
    overflow-y: auto;
    margin-top: 20px;
}

.frkd-actions-list {
    align-self: stretch;
    flex: 1;
    border: 1px solid #000;
    overflow: auto;
    padding: 10px 0;
    font-size: 24px;
    color: #ffa433;
}

.frkd-inner-section,
.frkd-akvw-inner-window {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}

.frkd-inner-section {
    overflow: auto;
    height: auto;
    align-self: stretch;
    flex: 1;
    flex-direction: row;
    gap: 20px;
    text-align: center;
    color: #fff;
}

/* AdminKursdetails.frkd-css */
.frkd-delete-popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    /* Dies stellt sicher, dass das Modal immer oben angezeigt wird */
}

.frkd-delete-popup {
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    width: 400px;
    /* oder die gewünschte Größe */
}


.frkd-inner-window {
    position: absolute;
    height: calc(100% - 20px);
    width: calc(100% - 267px);
    top: 10px;
    right: 16px;
    bottom: 11px;
    left: 251px;
    border-radius: 4px;
    background-color: #fdfdfd;
    border: 2px solid #777;
    box-sizing: border-box;
    overflow-x: auto;
    display: flex;
    flex-direction: column;
    padding: 38px 49px;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 20px;
}

.frkd-kursdetails {
    position: relative;
    background: linear-gradient(#e0e0e0, #e0e0e0), #fff;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    text-align: left;
    font-size: 20px;
    color: #000;
    font-family: "Verdana";
}

@media screen and (max-width: 960px) {
    .frkd-modulname {
        flex: unset;
        align-self: stretch;
    }

    .frkd-info-list-comp,
    .frkd-modul-comp {
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        gap: 0;
    }
}

@media screen and (max-width: 420px) {
    .frkd-modulname {
        flex: unset;
        align-self: stretch;
    }

    .frkd-info-list-comp,
    .frkd-modul-comp {
        flex-direction: column;
        gap: 10px;
        align-items: flex-start;
        justify-content: flex-start;
    }
}


.frkd-name-change-popup-overlay {
    color: #000;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.frkd-name-change-popup {
    background: #ffffff;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
    width: 300px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}