.inner-window3 {
  position: absolute;
  height: calc(100% - 20px);
  width: calc(100% - 258px);
  top: 10px;
  right: 11px;
  bottom: 10px;
  left: 247px;
  background-color: #fff;
  border: 2px solid #b52a2a;
  box-sizing: border-box;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding: 50px 30px;
  align-items: flex-start;
  justify-content: flex-start;
}
.fahrer-pruefung-nichtbestanden {
  position: relative;
  background-color: #e0e0e0;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}
