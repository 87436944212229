.loading-bar-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    height: 100vh;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(255, 255, 255, 0.5); 
}

.loading-bar-background {
    width: 50%;  /* Ändern Sie die Breite nach Ihren Wünschen */
    height: 10px;
    background-color: #f3f3f3;
    position: relative;
}

.loading-bar-container-label{
    font-size: 30px;
    font-weight: bold;
}

.loading-bar {
    width: 0;
    height: 100%;
    background-color: #383636;
    position: absolute;
}
