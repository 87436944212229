.amf-text {
  position: relative;
}
.amf-ueberschrift {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}


.amf-text-ueberschrift {
  position: relative;
  font-size: large;
}
.amf-emtpy,
.amf-ueberschrift-name {
  position: relative;
  font-weight: 600;
}
.amf-emtpy {
  text-decoration: underline;
  color: #d9d9d9;
}
.amf-fahrerlist,
.amf-list-headlines {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  overflow-y: auto;
}
.amf-fahrerlist {
  flex: 1;
  border-radius: 5px;
  background-color: #d9d9d9;
  border: 2px solid #575757;
  max-height: 40vh;
  overflow-y: auto;
  flex-direction: column;
  justify-content: flex-start;
  padding: 20px 50px;
  gap: 10px;

}
.amf-filter-headline {
  position: relative;
  text-decoration: underline;
  font-weight: 600;
}
.amf-btn-text {
  position: relative;
  font-size: 13px;
  font-weight: 600;
  font-family: "Verdana";
  color: #fff;
  text-align: left;
}
.amf-filter-btn {
  cursor: pointer;
  border: 1px solid #000;
  padding: 10px;
  background-color: #3a3a3a;
  flex: 1;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.amf-btn-text1 {
  align-self: stretch;
  position: relative;
  font-size: 13px;
  font-weight: 600;
  font-family: "Verdana";
  color: #000;
  text-align: center;
}
.amf-filter-reset-btn {
  cursor: pointer;
  border: 1px solid #000;
  padding: 5px 10px;
  background-color: #d9d9d9;
  flex: 1;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.amf-fahrer-input,
.amf-filter-inputs {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.amf-fahrer-input {
  border: 1px solid #777;
  font-weight: 600;
  font-family: "Verdana";
  font-size: 12px;
  background-color: #fff;
  align-self: stretch;
  border-radius: 5px;
  flex-direction: row;
  padding: 5px 10px;
}
.amf-filter-inputs {
  flex: 1;
  flex-direction: column;
  gap: 10px;
}
.amf-filter-container1 {
  align-self: stretch;
  background-color: #ffc987;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
}
.amf-filter-container {
  border-radius: 5px;
  background-color: #d9d9d9;
  border: 2px solid #575757;
  flex-direction: column;
  padding: 20px 50px;
  max-height: 40vh;

}
.amf-actioncontainer,
.amf-filter-container,
.amf-innerframe {
  align-self: stretch;
  flex: 1;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 10px;
}
.amf-actioncontainer {
  flex-direction: row;
  font-size: 13px;
  overflow-y: auto;
}
.amf-innerframe {
  background-color: #fff;
  flex-direction: column;
  padding: 20px;
}
.amf-uebersichtsnav-btn-text {
  align-self: stretch;
  position: relative;
  font-size: 20px;
  font-weight: 600;
  font-family: "Verdana";
  color: #000;
  text-align: center;
}
.amf-uebersichtsnav-btn {
  cursor: pointer;
  border: 1px solid #000;
  padding: 10px 50px;
  background-color: #fff;
  border-radius: 20px;
  align-items: center;
}
.addfahrertoschulung,
.amf-uebersicht-btn,
.amf-uebersichtsnav-btn {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.amf-uebersicht-btn {
  align-self: stretch;
  align-items: flex-end;
}
.addfahrertoschulung {
  border: #555555 5px solid;
  position: fixed;
  top: 10%;
  left: 10%;
  right: 10%;
  bottom: 10%;
  width: 80vw;
  height: 85vh;
  background: rgb(146, 142, 142);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  padding: 20px;
  box-sizing: border-box;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 30px;
  text-align: left;
  font-size: 13px;
  color: #000;
  font-family: "Verdana";
  z-index: 1001;
}