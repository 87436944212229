.akvw-inner-window {
  display: flex;
  flex-direction: column;
  position: absolute;
  height: calc(100% - 149px);
  width: calc(100% - 255px);
  top: 142px;
  right: 12px;
  bottom: 7px;
  left: 243px;
  background-color: #fff;
  overflow-y: auto;
  padding: 10px 50px 50px 50px;
  box-sizing: border-box;
}

.akvw-kursverwaltung {
  position: absolute;
  background-color: #e0e0e0;
  width: 100%;
  height: 100vh;
}
.akvw-tabs {
  display: flex;
  position: relative;
}

.akvw-tab {
  padding: 10px 100px;
  margin-right: -1px; /* Negative margin, damit Tabs ineinandergreifen */
  border: 1px solid #ccc;
  background-color: #f9f9f9;
  font-size: 17px;
  cursor: pointer;
  transition: all 0.3s ease; /* Sanfte Übergänge */
  border-bottom: none; /* Entfernt untere Border */
  z-index: 1; /* Niedriger z-index für nicht ausgewählte Tabs */
}

.akvw-tab.active {
  transform: scale(1.1); /* Vergrößert den aktiven Tab */
  z-index: 2; /* Höherer z-index für den aktiven Tab */
  background-color: white;
  border-top: 3px solid #f49401; /* Akzentfarbe für den aktiven Tab */
}

.akvw-tab-content {
  display: flex;       /* Enables flexbox layout */
  flex-direction: column; /* Stacks children vertically */
  height: 100%;        /* Takes full height of its parent */
}

.akvw-btn-text {
  position: relative;
  font-size: 13px;
  font-weight: 600;
  font-family: "Verdana";
  color: #fff;
  text-align: left;
}

.akvw-fragen-btn,
.akvw-fragen-btn1,
.akvw-fragen-section {
  align-self: self-end;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  width: 250px;
}

.akvw-fragen-btn,
.akvw-fragen-btn1 {
  cursor: pointer;
  border: 1px solid #000;
  padding: 10px 30px;
  background-color: #3a3a3a;
  border-radius: 5px;
}

.akvw-fragen-btn1 {
  background-color: #3a3a3a;
}