.modulname1 {
  position: relative;
  display: inline-block;
  width: 228px;
  height: 19px;
  flex-shrink: 0;
}

.modul-comp {
  align-self: stretch;
  border-radius: 5px;
  background-color: #e9e9e9;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  padding: 17px 16px 16px;
  align-items: flex-start;
  justify-content: flex-start;
}

.schulungsmodul-f11 {
  flex: 1;
  position: relative;
  font-size: 22px;
  font-weight: 600;
  width: 100%; 
  font-family: "Verdana";
  color: #434343;
  text-align: center;
  white-space: normal; /* Dies ist der Standardwert, aber es schadet nicht, es explizit anzugeben */
  overflow-wrap: break-word; /* Um sicherzustellen, dass der Text bei Bedarf umbrochen wird */

}



.bereich-list,
.info-list-comp {
  align-self: stretch;
  display: flex;
  align-items: space-between;
}

.info-list-comp {
  border-radius: 5px;
  background-color: #e9e9e9;
  overflow: hidden;
  flex-direction: row;
  padding: 17px 16px 16px;
  justify-content: space-between;
}

.info-list-comp:hover {
  cursor: pointer;
  background-color: #e9e9e9;
}

.bereich-list {
  flex: 1;
  border: 1px solid #000;
  overflow-x: auto;
  flex-direction: column;
  padding: 20px;
  justify-content: flex-start;
  gap: 30px;
}

.text {
  align-self: stretch;
  position: relative;
  font-size: 20px;
  font-weight: 600;
  font-family: "Verdana";
  color: #fff;
  text-align: center;
}

.pruefung-btn,
.uebersicht-btn1 {
  cursor: pointer;
  border: 0;
  width: 70%;
  height: 10vh;
  background-color: #ffa433;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}


.pruefung-btn{
  justify-content: center;
  width: 80%;
  height: 10vh;
  padding: 10px 10px;
  align-items: center;
  gap: 10px;
  text-align: center;
  align-self: center;
  font-size: 16px;
  color: #fff;
  font-family: "Verdana";
}

.pruefung-text {
  align-self: center;
  font-size: 20px;
  font-weight: 600;
  font-family: "Verdana";
  color: #fff;
  text-align: center;
}

.uebersicht-btn1 {
  background-color: #3a3a3a;
}

.infotext1 {
  position: relative;
  font-weight: 600;
  display: inline-block;
  width: 314px;
  height: 49px;
  flex-shrink: 0;
}

.actions-list1 {
  align-self: stretch;
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 50px;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  text-align: center;
  font-size: 16px;
  color: #777;
}


.inner-container {
  align-self: stretch;
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 50px;
  align-items: center;
  justify-content: flex-end;
  gap: 20px;
  text-align: center;
  font-size: 16px;
  color: #777;
}

.inner-section,
.inner-window6 {
  display: flex;
  align-items: flex-start;
}

.inner-section {
  align-self: stretch;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  text-align: left;
}

.inner-window6 {
  position: absolute;
  height: calc(100% - 20px);
  width: calc(100% - 267px);
  top: 10px;
  right: 16px;
  bottom: 11px;
  left: 251px;
  border-radius: 4px;
  background-color: #fdfdfd;
  border: 2px solid #777;
  box-sizing: border-box;
  overflow: hidden;
  flex-direction: column;
  padding: 15px 30px;
  box-sizing: border-box;
  justify-content: flex-start;
  gap: 20px;
}

.fahrer-kursansicht {
  position: relative;
  background-color: #e0e0e0;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  text-align: center;
  font-size: 20px;
  color: #000;
  font-family: "Verdana";
}

@media screen and (max-width: 960px) {
  .modul-comp {
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 0;
  }

  .schulungsmodul-f11 {
    flex: unset;
    align-self: stretch;
  }

  .info-list-comp {
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 0;
  }
}

@media screen and (max-width: 420px) {
  .modul-comp {
    flex-direction: column;
    gap: 10px;
    align-items: flex-start;
    justify-content: flex-start;
  }

  .schulungsmodul-f11 {
    flex: unset;
    align-self: stretch;
  }

  .info-list-comp {
    flex-direction: column;
    gap: 10px;
    align-items: flex-start;
    justify-content: flex-start;
  }
}