.afvw-carrier-input {
    display: flex;
    flex-direction: column;
}

.afvw-carrier-input {
    border: 1px solid #000;
    font-weight: 600;
    font-family: "Verdana";
    font-size: 13px;
    background-color: #fff;
    flex: 1;
    border-radius: 5px;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 10px 30px 10px 5px;
}

.afvw-suchen-btn-text {
    position: relative;
    font-size: 18px;
    font-weight: 600;
    font-family: "Verdana";
    color: #fff;
    text-align: center;
}

.afvw-carrier-input-container,
.afvw-suchen-btn {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.afvw-suchen-btn {
    cursor: pointer;
    border: 0;
    padding: 3px 0;
    background-color: #575757;
    border-radius: 10px;
    width: 110px;
    overflow: hidden;
    flex-shrink: 0;
    align-items: center;
    box-sizing: border-box;
}

.afvw-suchen-btn:hover {
    background-color: #676767;
}

.afvw-carrier-input-container {
    align-self: stretch;
    align-items: flex-start;
    padding: 0 150px;
    gap: 10px;
}

.afvw-inner-window {
    position: absolute;
    height: calc(100% - 16px);
    width: calc(100% - 255px);
    top: 9px;
    right: 12px;
    bottom: 7px;
    left: 243px;
    background-color: #fff;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 50px;
    box-sizing: border-box;
    gap: 10px;
}

.afvw-fahrerverwaltung {
    position: relative;
    background-color: #e0e0e0;
    width: 100%;
    height: 100vh;
    overflow: hidden;
}


.afvw-suggestions-container {
    top: 100%; /* Dies stellt sicher, dass es direkt unter dem Eingabefeld beginnt */
    border: 1px solid #ccc;
    max-height: 150px;
    overflow-y: auto;
    position: absolute;
    width: 100%;
    z-index: 1000;
}

.afvw-suggestion-item {
    padding: 10px;
    cursor: pointer;
    background-color: #eaeaea;
}

.afvw-suggestion-item:hover {
    background-color: #cccccc;
}

.afvw-input-wrapper {
    display: flex;
    flex-direction: column;
    position: relative;
}
