.lg-background-image-icon {
    position: absolute;
    height: calc(100% - 482px);
    width: 100%;
    top: 138px;
    right: 0;
    bottom: 344px;
    left: 0;
    max-width: 100%;
    overflow: hidden;
    max-height: 100%;
    object-fit: cover;
}

.lg-headline-text {
    margin: 0;
    font-weight: 800;
}

.lg-headline-subtext {
    margin: 0;
}

.lg-headline-txt {
    line-break: anywhere;
}

.lg-headline {
    align-self: center;
    position: relative;
    display: flex;
    align-items: center;
    height: 124px;
    flex-shrink: 0;
}

.lg-login-btn-text {
    position: relative;
    font-size: 14px;
    line-height: 28px;
    color: white;
    text-align: center;
}

.lg-form-container,
.lg-login-btn {
    display: flex;
    align-items: center;
    justify-content: center;
}

.lg-login-btn {
    cursor: pointer;
    border: 0;
    padding: 17px 59px;
    background-color: #3a3a3a;
    border-radius: 3px;
    overflow: hidden;
    flex-direction: row;
}

.lg-login-btn:hover {
    background-color: #4a4949;
}

.lg-form-container {
    position: absolute;
    top: calc(50% - 81px);
    left: calc(50% - 297.5px);
    border-radius: 20px;
    background-color: rgba(255, 178, 83, 0.7);
    width: 650px;
    height: 348px;
    flex-direction: column;
    padding: 50px 30px;
    box-sizing: border-box;
    gap: 20px;
}

.lg-login {
    position: absolute;
    background-color: var(--color-white);
    width: 100%;
    height: 100vh;
    overflow: hidden;
    text-align: center;
    font-size: 35px;
    color: #3b4754;
    font-family: var(--font-open-sans);
}