.fdans-inner-window6 {
  display: flex;
  align-items: flex-start;
}

.fdans-btn-zueruck {
  position: absolute;
  bottom: 20px;
  background-color: #777;
  width: 200px;
  height: 50px;
  border: none;
  border-radius: 4px;
  color: #fff;
  font-size: 20px;
  font-family: "Verdana";
  cursor: pointer;
}

.fdans-btn-weiter {
  position: absolute;
  bottom: 20px;
  right: 20px;         /* Hinzugefügt, um die Schaltfläche rechts zu positionieren */
  background-color: #777;
  width: 200px;
  height: 50px;
  border: none;
  border-radius: 4px;
  color: #fff;
  font-size: 20px;
  font-family: "Verdana";
  cursor: pointer;
}

.fdans-inner-window6 {
  position: relative;
  /* Gibt einen Kontext für absolut positionierte Kinder */
  height: calc(100% - 20px);
  width: calc(100% - 267px);
  top: 10px;
  right: 16px;
  bottom: 11px;
  left: 251px;
  border-radius: 4px;
  background-color: #fdfdfd;
  border: 2px solid #777;
  box-sizing: border-box;
  overflow-y: auto;
  flex-direction: column;
  padding: 15px 30px;
  box-sizing: border-box;
  justify-content: flex-start;
  gap: 50px;
}

.fdans-fahrer-kursansicht {
  position: relative;
  background-color: #e0e0e0;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  text-align: center;
  font-size: 20px;
  color: #000;
  font-family: "Verdana";
}