.akd-modulname {
    flex: 1;
    position: relative;
}

.akd-info-list-comp,
.akd-modul-comp {
    align-self: stretch;
    border-radius: 5px;
    background-color: #e9e9e9;
    padding: 17px 16px 16px;
    align-items: flex-start;
    justify-content: flex-start;
}

.akd-info-list-comp {
    background-color: #737373;
}

.akd-info-list-comp:hover {
    background-color: #616060;
    cursor: pointer;
    /* Hintergrundfarbe beim Darüberfahren */
}

.akd-schulung-lschen {
    position: relative;
    font-size: 18px;
    font-family: "Verdana";
    color: #fff;
    text-align: center;
}

.akd-delete-btn {
    cursor: pointer;
    border: 0;
    padding: 12px 24px;
    background-color: #ff9b43;
    border-radius: 5px;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.akd-speichern-btn {
    cursor: pointer;
    border: 0;
    padding: 12px 52px;
    background-color: #424242;
    border-radius: 10px;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    align-self: flex-start;
}


.akd-gltigkeitsdauer-ndern {
    display: inline-block;
    height: 25px;
    flex-shrink: 0;
}

.akd-gltigkeitsdauer-ndern,
.name1 {
    position: relative;
    line-height: 20px;
}

.iconsaxlinearcalendar {
    position: relative;
    width: 24px;
    height: 24px;
    overflow: hidden;
    flex-shrink: 0;
}

.akd-date2,
.akd-datepicker {
    display: flex;
    justify-content: flex-start;
}

.akd-datepicker {
    border-radius: 5px;
    background-color: #e2e1e5;
    border: 1px solid #565657;
    box-sizing: border-box;
    width: 100%;
    height: 51px;
    overflow: hidden;
    flex-shrink: 0;
    flex-direction: row;
    padding: 7px 13px;
    align-items: center;
    gap: 25px;
    font-size: 14px;
    color: #a6a6a6;
}

.akd-date2 {
    flex-direction: column;
    align-items: center;
    gap: 5px;
}

.akd-lower-details-section {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
    text-align: left;
    font-size: 16px;
    color: #000;
}

.akd-bereich-list {
    align-self: stretch;
    flex: 1;
    border: 1px solid #000;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    padding: 20px;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 30px;
}


.akd-informationsbereiche {
    position: relative;
}

.akd-label {
    display: flex;
    flex-direction: row;
    padding: 10px;
    align-items: center;
    justify-content: center;
}

.akd-hinzufgen {
    position: relative;
    font-size: 16px;
    font-family: "Verdana";
    color: #000;
    text-align: center;
}

.akd-add-btn {
    cursor: pointer;
    border: 1px solid #ff8d00;
    padding: 9px 11px;
    background-color: #ffba65;
    border-radius: 10px;
    box-sizing: border-box;
    width: 110px;
    overflow: hidden;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
}

.akd-change-btn {
    cursor: pointer;
    border: 1px solid #ff8d00;
    padding: 9px 11px;
    background-color: #ffea65;
    border-radius: 10px;
    box-sizing: border-box;
    width: 110px;
    overflow: hidden;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
}

.akd-add-btn,
.akd-change-btn,
.akd-actionsButtons,
.akd-delete-btn2 {
    display: flex;
    flex-direction: row;
}

.akd-delete-btn2 {
    cursor: pointer;
    border: 1px solid #ff325b;
    padding: 9px 7px;
    background-color: #f68080;
    border-radius: 5px;
    box-sizing: border-box;
    width: 110px;
    overflow: hidden;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
}

.akd-actionsButtons {
    align-items: flex-end;
    justify-content: flex-start;
    gap: 62px;
}

.akd-upper-information-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.akd-menuitem2-1-child {
    position: relative;
    border-radius: 50%;
    background-color: #fff;
    border: 1px solid #000;
    box-sizing: border-box;
    width: 20px;
    height: 20px;
}

.akd-grundlagen {
    position: relative;
    font-size: 22px;
    font-weight: 600;
    width: 100%; 
    font-family: "Verdana";
    color: #434343;
    text-align: center;
    white-space: normal; /* Dies ist der Standardwert, aber es schadet nicht, es explizit anzugeben */
    overflow-wrap: break-word; /* Um sicherzustellen, dass der Text bei Bedarf umbrochen wird */
}

.akd-grundlagen-wrapper,
.akd-menuitem2-1 {
    width: 90%;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.akd-grundlagen-wrapper {
    border-radius: 5px;
    /* background-color: #d9d9d9; */
    background-color: #e9e9e9;
    overflow: hidden;
    flex-shrink: 0;
    width: 90%;
    margin-top: 20px;
    padding: 17px 63px;
    box-sizing: border-box;
    justify-content: center;
    align-items: center;
}


.akd-menuitem2-1 {
    cursor: pointer;
    border: 0;
    padding: 0;
    background-color: transparent;
    justify-content: center;
    gap: 20px  ;
    width: 100%;
}

.akd-actions-list,
.akd-frame-parent,
.akd-menuitems2 {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

.akd-actions-list,
.akd-frame-parent {
    align-items: center;
    gap: 10px;
}

.akd-frame-parent {
    height: 328px;
    border-top: #000 1px solid;
    padding-top: 10px;
    overflow-y: auto;
    margin-top: 20px;
    width: 90%;
}

.akd-actions-list {
    align-self: stretch;
    flex: 1;
    border: 1px solid #000;
    overflow: auto;
    padding: 10px 0;
    font-size: 24px;
    color: #ffa433;
    display: flex;
}

.akd-inner-section,
.akd-inner-window {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}

.akd-inner-section {
    overflow: auto;
    height: auto;
    align-self: stretch;
    flex: 1;
    flex-direction: row;
    gap: 20px;
    text-align: center;
    color: #fff;
}

/* AdminKursdetails.css */
.akd-delete-popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    /* Dies stellt sicher, dass das Modal immer oben angezeigt wird */
}

.akd-delete-popup {
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    width: 400px;
    /* oder die gewünschte Größe */
}


.akd-inner-window {
    position: absolute;
    height: calc(100% - 20px);
    width: calc(100% - 267px);
    top: 10px;
    right: 16px;
    bottom: 11px;
    left: 251px;
    border-radius: 4px;
    background-color: #fdfdfd;
    border: 2px solid #777;
    box-sizing: border-box;
    overflow-x: auto;
    display: flex;
    flex-direction: column;
    padding: 38px 49px;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 20px;
}

.akd-admin-kursdetails {
    position: relative;
    background: linear-gradient(#e0e0e0, #e0e0e0), #fff;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    text-align: left;
    font-size: 20px;
    color: #000;
    font-family: "Verdana";
}

@media screen and (max-width: 960px) {
    .akd-modulname {
        flex: unset;
        align-self: stretch;
    }

    .akd-info-list-comp,
    .akd-modul-comp {
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        gap: 0;
    }
}

@media screen and (max-width: 420px) {
    .akd-modulname {
        flex: unset;
        align-self: stretch;
    }

    .akd-info-list-comp,
    .akd-modul-comp {
        flex-direction: column;
        gap: 10px;
        align-items: flex-start;
        justify-content: flex-start;
    }
}


.akd-name-change-popup-overlay {
    color: #000;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.akd-name-change-popup {
    background: #ffffff;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
    width: 300px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.akd-delete-popup button {
    padding: 12px 24px;  /* Vergrößert den Abstand zwischen Text und Buttonrand */
    font-size: 16px;    /* Vergrößert die Schriftgröße */
    margin-right: 10px; /* Fügt etwas Abstand zwischen den Buttons hinzu */
    cursor: pointer;    /* Ändert den Mauszeiger zu einer Hand, wenn er über dem Button schwebt */
}

/* Optional: Fügt einen Hover-Effekt hinzu, um die Interaktivität zu erhöhen */
.akd-delete-popup button:hover {
    background-color: #f5f5f5;
}
