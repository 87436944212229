.die-3-kummernummer {
  flex: 1;
  position: relative;
  font-weight: 600;
}

.frage {
  flex: 1;
  align-items: flex-start;
  justify-content: flex-start;
}

.antwort-comp,
.buttons,
.frage {
  display: flex;
  flex-direction: row;
}

.buttons {
  cursor: pointer;
  width: 30px;
  height: 30px;
  align-items: center;
  justify-content: center;
}

.antwort-comp {
  flex: 1;
  align-self: stretch;
  border-radius: 5px;
  background-color: #fff;
  border: 1px solid #000;
  overflow: hidden;
  flex-shrink: 0;
  flex-wrap: wrap;
  padding: 17px 16px 16px;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 50px;
  text-align: left;
  font-size: 20px;
  color: #000;
  font-family: "Verdana";
}

@media screen and (max-width: 960px) {
  .frage {
    flex: unset;
    align-self: stretch;
  }

  .antwort-comp {
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 0;
  }
}

@media screen and (max-width: 420px) {
  .frage {
    flex: unset;
    align-self: stretch;
  }

  .antwort-comp {
    flex-direction: column;
    gap: 10px;
    align-items: flex-start;
    justify-content: flex-start;
  }
}