.fku-modulname2,
.fku-teilnehmen6 {
  position: relative;
}

.fku-teilnehmen6 {
  align-self: stretch;
  font-size: 16px;
  font-weight: 600;
  font-family: "Verdana";
  color: #fff;
  text-align: center;
}

.fku-teilnehmen-btn3 {
  cursor: pointer;
  border: 0;
  padding: 3.2247161865234375px 45px;
  background-color: #3a3a3a;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}


.fku-modul-list-comp3 {
  align-self: stretch;
  border-radius: 5px;
  background-color: #e9e9e9;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  padding: 17px 16px 16px;
  align-items: flex-start;
  justify-content: space-between;
}

.fku-inner-window7 {
  position: absolute;
  height: calc(100% - 165px);
  width: calc(100% - 253px);
  top: 149px;
  right: 10px;
  bottom: 16px;
  left: 243px;
  background-color: #fff;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding: 50px 20px;
  box-sizing: border-box;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
}

.fku-fahrer-kursbersicht {
  position: relative;
  background-color: #e0e0e0;
  width: 100%;
  height: 100vh;
  overflow-y: auto;
  text-align: left;
  font-size: 20px;
  color: #000;
  font-family: "Verdana";
}

@media screen and (max-width: 960px) {
  .fku-modul-list-comp3 {
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 0;
  }
}

@media screen and (max-width: 420px) {
  .fku-modul-list-comp3 {
    flex-direction: column;
    gap: 10px;
    align-items: flex-start;
    justify-content: flex-start;
  }
}