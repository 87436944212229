.fpu-teilnehmen4 {
  align-self: stretch;
  position: relative;
  font-size: 20px;
  font-weight: 600;
  font-family: "Verdana";
  color: #fff;
  text-align: center;
}
.fpu-starten-btn {
  cursor: pointer;
  border: 0;
  padding: 20px 100px;
  background-color: #ffa433;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.fpu-infotext {
  align-self: stretch;
  position: relative;
  font-weight: 600;
}
.fpu-uebersicht-btn {
  cursor: pointer;
  border: 0;
  padding: 20px 100px;
  background-color: #3a3a3a;
  border-radius: 20px;
  align-items: center;
  justify-content: flex-start;
}
.fpu-actions-list-1,
.fpu-inner-window5,
.fpu-uebersicht-btn {
  display: flex;
  flex-direction: column;
}
.fpu-actions-list-1 {
  align-self: stretch;
  flex: 1;
  padding: 50px 0;
  align-items: center;
  justify-content: center;
  gap: 20px;
}
.fpu-inner-window5 {
  position: absolute;
  height: calc(100% - 20px);
  width: calc(100% - 267px);
  top: 10px;
  right: 16px;
  bottom: 11px;
  left: 251px;
  border-radius: 4px;
  background-color: #fdfdfd;
  border: 2px solid #777;
  box-sizing: border-box;
  overflow: hidden;
  flex-direction: column;
  padding: 15px 30px;
  box-sizing: border-box;
  justify-content: flex-start;
  gap: 50px;
}
.fpu-fahrer-pruefungsstart {
  position: relative;
  background-color: #e0e0e0;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  text-align: center;
  font-size: 36px;
  color: #ffa433;
  font-family: "Verdana";
}
