/* Grundlegende Formatierung für das select Element */
.custom-select {
    width: fit-content;
    padding: 10px; /* Fügt innen Abstand hinzu */
    margin: 0; /* Kein Außenabstand */
    border: 1px solid #ccc; /* Fügt einen Rahmen hinzu */
    border-radius: 4px; /* Abrunden der Ecken */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Schatten für etwas Tiefe */
    background-color: white; /* Hintergrundfarbe */
    appearance: none; /* Entfernt standardmäßige Browser-Styles */
    -webkit-appearance: none; /* Speziell für Safari und Chrome */
    -moz-appearance: none; /* Speziell für Firefox */
    font-size: large;
  }

 
  
  /* Pfeil-Icon für das select Element mit einem Unicode-Zeichen */
  .custom-select::after {
    content: '\25BC'; /* Unicode für ein Pfeil nach unten */
    position: absolute;
    top: 0;
    right: 10px;
    padding: 10px;
    background-color: white;
    pointer-events: none; /* Verhindert, dass das Pseudo-Element klickbar ist */
  }
  
  /* Stil für das hover Zustand */
  .custom-select:hover {
    border-color: #888; /* Dunklere Rahmenfarbe beim Darüberfahren */
  }
  
  /* Stil für das focus Zustand */
  .custom-select:focus {
    border-color: #555; /* Noch dunklere Rahmenfarbe beim Fokus */
    outline: none; /* Entfernt den standardmäßigen Browser-Fokus */
  }
  