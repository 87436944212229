.afhb-list-component1 {
  display: flex;
  flex: 1;
  align-items: stretch;
  align-self: stretch;
  /* Kinder strecken, um die volle Höhe des Containers zu füllen */
  background-color: #bcbcbc;
  flex-direction: row;
  margin-bottom: 10px;
  padding: 10px 20px;
  border-radius: 5px;
  width: 100%;
}

.afhb-fahrer-table {
  flex: 1;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  position: relative;
}

.afhb-fahrer-table:not(:last-child) {
  border-right: 1px solid #000;
}

.amf-hinzufuegen-btn {
  display: flex;
  cursor: pointer;
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  align-items: center;
  justify-content: center;
}