.b {
  position: relative;
}
.active {
  background-color: darkgrey; /* Or whatever color you want */
}

.fragen-box {
  border: 1px solid #000;
  flex-wrap: wrap;
  padding: 10px;
}
.fragen-box,
.fragen-box-list,
.fragen-box1 {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.fragen-box1 {
  border: 1px solid #000;
  padding: 10px;
}
.fragen-box-list {
  border-radius: 5px;
  background-color: #e9e9e9;
  flex-wrap: wrap;
  padding: 17px 16px 16px;
  gap: 10px;
  text-align: center;
  font-size: 20px;
  color: #000;
  font-family: "Verdana";
}
@media screen and (max-width: 960px) {
  .fragen-box-list {
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 0;
  }
}
@media screen and (max-width: 420px) {
  .fragen-box-list {
    flex-direction: column;
    gap: 10px;
    align-items: flex-start;
    justify-content: flex-start;
  }
}
