.infotext2,
.teilnehmen7 {
  align-self: stretch;
  position: relative;
  font-weight: 600;
}
.teilnehmen7 {
  font-size: 20px;
  font-family: "Verdana";
  color: #fff;
  text-align: center;
}
.repeat-btn {
  cursor: pointer;
  border: 0;
  padding: 20px 100px;
  background-color: #3a3a3a;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.teilnehmen8 {
  align-self: stretch;
  position: relative;
  font-size: 20px;
  font-weight: 600;
  font-family: "Verdana";
  color: #000;
  text-align: center;
}
.actions-list2,
.othermodules-btn1 {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.othermodules-btn1 {
  cursor: pointer;
  border: 2px solid #000;
  padding: 20px 100px;
  background-color: #fff;
  border-radius: 20px;
  justify-content: flex-start;
}
.actions-list2 {
  align-self: stretch;
  flex: 1;
  padding: 50px 0;
  justify-content: center;
  gap: 50px;
  text-align: center;
  font-size: 36px;
  color: #b52a2a;
  font-family: "Verdana";
}
