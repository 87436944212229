.set-teilnehmen {
  font-size: 20px;
  font-weight: 600;
  font-family: "Verdana";
  color: #000;
  margin: 0;
  padding: 0;
  display: flex; /* Stellen Sie sicher, dass dies ein Flex Container ist */
  align-items: center; /* Zentriert den Inhalt vertikal */
  justify-content: center; /* Zentriert den Inhalt horizontal */
  flex: 1; /* Erlaubt dem Element, den verfügbaren Platz auszufüllen */
}

.set-inner-window,
.set-othermodules-btn {
  background-color: #fff;
  display: flex;
  justify-content: flex-start;
}
.set-othermodules-btn {
  border: 2px solid #000;
  border-radius: 20px;
  display: flex; /* Definiert den Container als Flex-Container */
  align-items: center; /* Zentriert die Elemente vertikal */
  justify-content: space-between; /* Verteilt die Elemente mit Raum dazwischen */
  gap: 20px; /* Optional, fügt einen Abstand zwischen den Elementen hinzu */
  padding: 20px;
}


.set-inner-window {
  position: absolute;
  height: calc(100% - 159px);
  width: calc(100% - 258px);
  top: 149px;
  right: 11px;
  bottom: 10px;
  left: 247px;
  box-sizing: border-box;
  overflow: hidden;
  flex-direction: column;
  padding: 50px 30px;
  align-items: center;
}
.set-settings {
  position: relative;
  background-color: #e0e0e0;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}
