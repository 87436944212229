.profile-picture-child {
  position: relative;
  border-radius: 50%;
  background-color: #ffb253;
  width: 75px;
  height: 75px;
  z-index: 0;
}

.profile-picture-icon {
  position: absolute;
  margin: 0 !important;
  height: 69.52%;
  width: 66.67%;
  top: 14.67%;
  right: 17.33%;
  bottom: 15.81%;
  left: 16%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: cover;
  z-index: 0;
}

.profile-picture {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
  gap: 10px;
}

.username {
  font-size: 15px;
  white-space: nowrap; /* Ensure the username does not wrap to the next line */
}

.username,
.userrole {
  position: relative;
  display: inline-block;
  height: 20px;
  flex-shrink: 0;
}

.profile-data {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 4px;
}

.profile,
.profile-section {
  display: flex;
  padding: 0 0 0 20px;
  box-sizing: border-box;
}

.profile {
  background-color: #e0e0e0;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 9px;
  width: auto; /* Adjust width automatically based on content */
  padding: 10px; /* Add some padding for better spacing */
  border-radius: 5px; /* Optional: add border radius for better look */
  min-width: 200px; /* Set minimum width to 200px */
}

.profile-section {
  width: auto; /* Adjust width automatically based on content */
  height: auto; /* Adjust height automatically based on content */
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.medlog-logo-icon {
  position: relative;
  width: 200px;
  height: 97px;
  object-fit: cover;
}

.header,
.logo {
  display: flex;
  align-items: flex-start;
}

.logo {
  flex-direction: column;
  justify-content: flex-start;
  padding: 20px 10px 10px;
}

.header {
  position: absolute;
  width: calc(100% - 235px);
  top: 0;
  right: 0;
  left: 235px;
  background-color: #fff;
  flex-direction: row;
  justify-content: space-between;
  text-align: left;
  font-size: 14px;
  color: #000;
  font-family: "Verdana";
}
