.afvw-list-item,
.afvw-list-item-header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    background-color: #d9d9d9;
}

.afvw-list-item {
    margin-bottom: 10px;
}   

.afvw-list-item-item,
.afvw-list-item-header-item,
.afvw-list-item-header-item-begin,
.afvw-list-item-header-item-end,
.afvw-list-item-item-begin,
.afvw-list-item-item-end {
    flex: 1;
    text-align: center;
    border-right: 1px solid black;
    padding: 0 10px;
}

/* Entfernen Sie den rechten Rand für das letzte Element */
.afvw-list-item-item-end,
.afvw-list-item-header-item-end {
    flex: 1.5;
    border-right: none;
}

.afvw-list-item-fertig-btn-text {
    font-size: 18px;
    font-weight: 600;
    font-family: "Verdana";
    color: #000000;
    text-align: center;
}


.afvw-list-item-header-item,
.afvw-list-item-header-item-begin,
.afvw-list-item-header-item-end
{
border: none;
font-weight: bold;
}

.afvw-list-item-header{
background: transparent;
}

.afvw-list-item-fertig-btn {
    margin: 0 50px;
    cursor: pointer;
    border: 0;
    padding: 3px 50px;
    background-color: #ffba65;
    border-radius: 10px;
    overflow: hidden;
    align-items: center;
    justify-content: center;
}

.afvw-list-item-fertig-btn1 {
    margin: 0 10px;
    cursor: default;
    border: 0;
    padding: 3px 50px;
    background-color: #ffba65;
    border-radius: 10px;
    overflow: hidden;
    align-items: center;
    justify-content: center;
}


.afvw-list-item-fertig-btn:hover {
    background-color: #ffad4a;
}