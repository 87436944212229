.modulname3 {
  position: relative;
}

.text23,
.zertifikat-btn {
  display: flex;
  justify-content: center;
}

.text23 {
  position: relative;
  font-size: 16px;
  font-weight: 600;
  font-family: "Verdana";
  color: #000;
  text-align: center;
  align-items: center;
  width: 250px;
  flex-shrink: 0;
}

.zertifikat-btn {
  cursor: pointer;
  border: 1px solid #000;
  padding: 3.2247161865234375px 20px;
  background-color: #fff;
  border-radius: 20px;
  flex-direction: row;
  align-items: flex-start;
}

.text33 {
  align-self: stretch;
  position: relative;
  font-size: 16px;
  font-weight: 600;
  font-family: "Verdana";
  color: #fff;
  text-align: center;
}

.buttons-frame,
.teilnehmen-btn4 {
  border-radius: 20px;
  display: flex;
  align-items: center;
}

.teilnehmen-btn4 {
  cursor: pointer;
  border: 0;
  padding: 3.2247161865234375px 50px;
  background-color: #34be39;
  flex-direction: column;
  justify-content: flex-start;
}

.buttons-frame {
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  gap: 30px;
}

.modul-list-comp5 {
  align-self: stretch;
  border-radius: 5px;
  background-color: #e9e9e9;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  padding: 17px 16px 16px;
  align-items: flex-start;
  justify-content: space-between;
  text-align: left;
  font-size: 20px;
  color: #000;
  font-family: "Verdana";
}

@media screen and (max-width: 960px) {
  .modul-list-comp5 {
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 0;
  }
}

@media screen and (max-width: 420px) {
  .modul-list-comp5 {
    flex-direction: column;
    gap: 10px;
    align-items: flex-start;
    justify-content: flex-start;
  }
}