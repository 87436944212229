.amsh-inputitem-comp {
    border: 1px solid #000;
    font-weight: 600;
    font-family: "Verdana";
    font-size: 18px;
    background-color: #fff;
    align-self: stretch;
    padding: 10px 30px;
    width: 500px;
}

.amsh-inputitem-comp2 {
    border: 1px solid #000;
    font-weight: 600;
    font-family: "Verdana";
    font-size: 18px;
    background-color: #fff;
    align-self: stretch;
    justify-content: center;
    align-items: center;
    padding: 0px 0px;
    width: 500px;
}

.amsh-input-container{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 20px;
}

.amsh,
.amsh-inputitem-comp,
.amsh-inputitem-comp2 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

.amsh-inputitem-comp2 {
    align-self: stretch;
    background-color: #fff;
    border: 1px solid #000;
    width: 560px;
    padding: 10px 30px;
}

.amsh {
    gap: 50px;
}

.amsh-uebersichtsnav-btn-text {
    align-self: stretch;
    position: relative;
    font-size: 20px;
    font-weight: 600;
    font-family: "Verdana";
    color: #fff;
    text-align: center;
}

.amsh-uebersicht-btn,
.amsh-uebersichtsnav-btn {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.amsh-uebersichtsnav-btn {
    cursor: pointer;
    border: 1px solid #000;
    padding: 10px 50px;
    background-color: #3a3a3a;
    border-radius: 20px;
    align-items: center;
}

.amsh-uebersicht-btn {
    align-self: stretch;
    align-items: flex-end;
}

.admin-modal-schulung-hinzufueg {
    border: #555555 5px solid;
    overflow-y: auto;
    background-color: #e0e0e0;
    width: 80vw;
    height: 80vh;
    position: fixed;
    /* Hinzugefügt, um das Modal immer zentriert zu halten */
    top: 50%;
    /* Zusammen mit transform wird dies das Modal vertikal zentrieren */
    left: 50%;
    /* Zusammen mit transform wird dies das Modal horizontal zentrieren */
    transform: translate(-50%, -50%);
    /* Dies verschiebt das Modal zurück um die Hälfte seiner Breite und Höhe */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 50px;
    box-sizing: border-box;
    z-index: 2000; /* eine hohe z-index Nummer, um sicherzustellen, dass es über anderen Inhalten schwebt */
}

.amsh-input-container label {
    width: 300px; /* zum Beispiel, anpassen je nach Bedarf */
    font-size: 23px;
    justify-content: center;
    align-self: center;
}

@keyframes flickerAnimation {
    0%, 100% { opacity: 1; } /* Text is fully visible */
    50% { opacity: 0.2; } /* Text is fully invisible */
}

