.ql-picker.ql-font .ql-picker-label[data-value="Arial"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="Arial"]::before {
    font-family: "Arial", sans-serif;
    content: "Arial";
}

.ql-picker.ql-font .ql-picker-label[data-value="Verdana"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="Verdana"]::before {
    font-family: "Verdana", sans-serif;
    content: "Verdana";
}

.ami-editor {
    height: 100%;
    /* Ensure this element takes full height of its parent */
}

.ami-editor .ql-editor {
    height: 100vh;
    /* This ensures the editable area uses all available space */
    min-height: 50%;
    /* Reset any minimum height that might be interfering */
}


/* Set content font-families */
.ql-font-Arial {
    font-family: "Arial";
}

.ql-font-Verdana {
    font-family: "Verdana";
}

.ql-editor {
    font-family: 'Verdana', sans-serif;
    /* Set Verdana as the default font */
}

.ql-customLink::before {
    content: 'Phishinglink';
}


/* Toolbar font selector */
.ql-picker.ql-font .ql-picker-label[data-value="Arial"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="Arial"]::before {
    font-family: "Arial", sans-serif;
    content: "Arial";
}

.ql-picker.ql-font .ql-picker-label[data-value="Verdana"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="Verdana"]::before {
    font-family: "Verdana", sans-serif;
    content: "Verdana";
}

.ql-picker.ql-font .ql-picker-label[data-value="Calibri"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="Calibri"]::before {
    font-family: "Calibri", sans-serif;
    content: "Calibri";
}

/* Content area styling for fonts */
.ql-font-Arial {
    font-family: "Arial";
}

.ql-font-Verdana {
    font-family: "Verdana";
}

.ql-font-Calibri {
    font-family: "Calibri";
}
