.time-selector {
    position: relative;
    width: 100%;
}

.time-selector-display {
    border: 1px solid #000;
    font-weight: 600;
    font-family: "Verdana";
    font-size: 18px;
    background-color: #fff;
    padding: 10px 30px;
    cursor: pointer;
    text-align: left;
}

.selected-time {
    border: 1px solid #ccc;
    padding: 10px;
    cursor: pointer;
}

.time-inputs {
    background: #fff;
    margin-top: 20px;
}

.time-inputs label {
    display: block;
    margin-bottom: 10px;
}

.time-inputs button {
    display: block;
    width: 50%;
}

.time-input {
    display: block;
    width: 50%;
}
