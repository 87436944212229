.amfh-btn-text {
    position: relative;
    font-weight: 600;
}

.amfh-checkbox,
.amfh-input-frage {
    background-color: #fff;
    border: 1px solid #000;
    box-sizing: border-box;
}

.amfh-input-frage {
    border-radius: 5px;
    width: 696px;
    display: flex;
    flex-direction: column;
    padding: 10px 30px 10px 5px;
    align-items: flex-start;
    justify-content: flex-start;
}

.amfh-checkbox {
    position: relative;
    width: 20px;
    height: 20px;
}

.amfh-frage-option,
.amfh-fragen-section,
.amfh-text-frame {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.amfh-text-frame {
    border: 1px solid #000;
    padding: 5px;
    width: 300px;
}

.amfh-delete-option {
    cursor: pointer;
    color: red;
    margin-left: 10px;
}


.amfh-frage-option,
.amfh-fragen-section {
    gap: 10px;
}

.amfh-fragen-section {
    overflow-y: auto;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
}

.amfh-image-icon {
    align-self: stretch;
    flex: 1;
    position: relative;
    max-width: 100%;
    overflow: hidden;
    max-height: 100%;
    object-fit: cover;
}

.amfh-inner-section {
    max-height: 30vh;
    /* Oder was auch immer die gewünschte maximale Höhe ist */
    overflow-y: auto;
    /* Ermöglicht das Scrollen bei Bedarf */
    align-self: stretch;
    overflow: auto;
    flex: 1;
    background-color: #fff;
    display: flex;
    flex-direction: row;
    padding: 50px;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 50px;
}

.amfainputtext {
    position: relative;
    font-size: 18px;
    font-weight: 600;
    font-family: "Verdana";
    color: #000;
    text-align: left;
}

.amfh-inputitem-comp {
    width: 300px;

    cursor: pointer;
    border: 1px solid #000;
    padding: 10px 30px;
    background-color: #f0efef;
    align-items: center;
}

.amfh-inputitem-comp,
.amfh-inputitem-comp1,
.amfh-mid-section,
.amfh-side-buttons {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.amfh-inputitem-comp1 {
    cursor: pointer;
    border: 1px solid #000;
    padding: 10px 30px;
    background-color: #f0efef;
    align-self: stretch;
    align-items: center;
}

.amfh-mid-section,
.amfh-side-buttons {
    align-items: flex-start;
    gap: 30px;
}

.amfh-uploaded-images-section {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-top: 10px;
}

.amfh-uploaded-image img {
    max-width: 50%;
    /* oder wie gewünscht */
    max-height: auto;
    /* oder wie gewünscht */
}

@keyframes flickerAnimation {
    0%, 100% { opacity: 1; } /* Text is fully visible */
    50% { opacity: 0.2; } /* Text is fully invisible */
}



.amfh-mid-section {
    align-self: stretch;
    flex: 1;
    flex-direction: row;
    font-size: 14px;
}

.amfh-text {
    position: relative;
}

.amfh-uebersichtsnav-btn-text {
    align-self: stretch;
    position: relative;
    font-size: 20px;
    font-weight: 600;
    font-family: "Verdana";
    color: #fff;
    text-align: center;
}

.amfh-uebersichtsnav-btn {
    cursor: pointer;
    border: 1px solid #000;
    padding: 10px 50px;
    background-color: #3a3a3a;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

.amfh-ueberschrift {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    font-size: 20px;
}

.admin-modal-frage-hinzufgen {
    border: #555555 5px solid;
    background-color: #e0e0e0;
    width: 80vw;
    height: 80vh;
    position: fixed;
    /* Hinzugefügt, um das Modal immer zentriert zu halten */
    top: 50%;
    /* Zusammen mit transform wird dies das Modal vertikal zentrieren */
    left: 50%;
    /* Zusammen mit transform wird dies das Modal horizontal zentrieren */
    transform: translate(-50%, -50%);
    /* Dies verschiebt das Modal zurück um die Hälfte seiner Breite und Höhe */
    display: flex;
    flex-direction: column;
    padding: 50px;
    box-sizing: border-box;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 30px;
    z-index: 1000;
    text-align: left;
    font-size: 13px;
    color: #000;
    font-family: "Verdana";
}