.afw-fragennummer,
.afw-fragentext {
    position: relative;
}

.afw-fragentext {
    font-size: 14px;
    font-weight: 600;
}

.afw-lc-text-section {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
}

.afw-checkbox-upper {
    position: relative;
    background-color: #fff;
    border: 1px solid #000;
    box-sizing: border-box;
    width: 20px;
    height: 20px;
}

.afw-list-component-upper-secti {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.afw-checkbox {
    cursor: pointer;
    position: relative;
    background-color: #fff;
    border: 1px solid #000;
    box-sizing: border-box;
    width: 20px;
    height: 20px;
}

.afw-fragentext1 {
    position: relative;
    font-weight: 600;
}

.afw-inner-fragen-section {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 50px;
    font-size: 14px;
}

.afw-btn-text {
    position: relative;
    font-size: 13px;
    font-weight: 600;
    font-family: "Verdana";
    color: #fff;
    text-align: left;
}



.afw-questionImage {
    padding: 10px;
    border: 1px solid #000;
}

.afw-filter-btn,
.afw-filter-btn1 {
    cursor: pointer;
    border: 1px solid #000;
    padding: 10px 30px;
    background-color: #3a3a3a;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

.afw-filter-btn1 {
    background-color: #ffa433;
}

.afw-button-section,
.afw-list-component {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    gap: 10px;
}

.afw-list-component {
    background-color: #d9d9d9;
    flex-direction: column;
    padding: 10px;
    align-items: flex-start;
    justify-content: center;
    max-width: 100%;
    overflow-x: auto;
    margin-bottom: 10px;
}

.afw-fragen-btn,
.afw-fragen-btn1,
.afw-fragen-section {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

.afw-fragen-section {
    flex: 1;
}

.afw-fragen-btn,
.afw-fragen-btn1 {
    cursor: pointer;
    border: 1px solid #000;
    padding: 15px;
    background-color: #ffa433;
    border-radius: 5px;
}

.afw-fragen-btn1 {
    background-color: #3a3a3a;
}

.afw-fragen-actions-buttons {
    flex-direction: column;
    align-items: center;
    gap: 10px;
}

.afw-fragen-actions-buttons,
.afw-input,
.afw-inputs {
    display: flex;
    justify-content: flex-start;
}

.afw-input {
    width: 100%;
    /* Setzt die Breite des Inputs auf automatisch, basierend auf seinem Inhalt */
    padding: 10px;
    /* Sie können den Innenabstand nach Ihren Wünschen anpassen */
    border: 1;
    font-weight: 600;
    font-family: "Verdana";
    font-size: 13px;
    background-color: #fff;
    border-radius: 5px;
    flex-direction: row;
    padding: 10px 30px;
    align-items: flex-start;
}

.afw-input-new {
    /* Sie können den Innenabstand nach Ihren Wünschen anpassen */
    border: 1;
    font-weight: 600;
    font-family: "Verdana";
    font-size: 13px;
    background-color: #fff;
    border-radius: 5px;
    flex-direction: row;
    text-align: center;
    align-items: flex-start;
}

.afw-inputs {
    flex-direction: column;
    align-items: flex-end;
    gap: 10px;
}

.afw-uebersichtsnav-btn-text {
    align-self: stretch;
    position: relative;
    font-size: 13px;
    font-weight: 600;
    font-family: "Verdana";
    color: #000;
    text-align: center;
}

.afw-uebersichtsnav-btn {
    cursor: pointer;
    border: 1px solid #000;
    padding: 10px 50px;
    background-color: #fff;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}


.afw-uebersichtsspeichern-btn {
    cursor: pointer;
    border: 1px solid #000;
    padding: 10px 50px;
    background-color: #fff;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

.afw-lower-section {
    align-self: stretch;
    display: flex;
    gap: 30px;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.afw-innerwindow {
    position: absolute;
    height: calc(100% - 20px);
    width: calc(100% - 267px);
    top: 10px;
    right: 16px;
    bottom: 11px;
    left: 251px;
    border-radius: 4px;
    background-color: #fdfdfd;
    border: 2px solid #777;
    box-sizing: border-box;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    padding: 50px 50px 10px 50px;
    box-sizing: border-box;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
}

.afw-input-group {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
    /* Reduziert den Abstand zwischen Label und Input */
    /* Restliche Eigenschaften... */
}


.afw-input {
    flex: 1;
    text-align: center;
    /* Gibt dem Eingabefeld mehr Platz */
}






.admin-fragenverwaltung {
    position: relative;
    background-color: #e0e0e0;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    text-align: left;
    font-size: 20px;
    color: #000;
    font-family: "Verdana";
}

.afw-inputs-layout {
    display: flex;
    flex-wrap: wrap;
    gap: 50px;
    /* Abstand zwischen den Gruppen */
}

.afw-input-group {
    display: flex;
    /* Definiert den Container als Flex-Container */
    align-items: center;
    /* Zentriert die Inhalte vertikal */
    flex-basis: calc(50% - 10px);
    max-width: calc(50% - 10px);
    box-sizing: border-box;
}

.afw-input-group.full-width {
    flex-basis: 100%;
    max-width: 100%;
}

.afw-input-group.full-width {
    flex-basis: 100%;
    /* Breite für die dritte Gruppe */
    max-width: 100%;
}

.afw-inputs-layout div {
    display: flex;
    flex-direction: column;
    align-items: center;
}


.afw-label {
    text-align: center;
    font-size: 17px;
    /* Zentriert den Text im Label */
    display: block;
    /* Stellt sicher, dass das Label die volle Breite einnimmt */
    margin-bottom: 5px;
    white-space: pre-line;
    /* Optional: Fügt einen Abstand unter dem Label hinzu */
}


.afw-input {
    text-align: center;
    margin-bottom: 10px;
    /* Abstand zwischen Input und nächstem Label */
}