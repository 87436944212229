.frku-modulname2,
.frku-teilnehmen6 {
    position: relative;
}

.frku-teilnehmen6 {
    align-self: stretch;
    font-size: 16px;
    font-weight: 600;
    font-family: "Verdana";
    color: #fff;
    text-align: center;
}

.frku-teilnehmen-btn3 {
    cursor: pointer;
    border: 0;
    padding: 3.247161865234375px 45px;
    background-color: #ff8c00;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}


.frku-modul-list-comp3 {
    align-self: stretch;
    border-radius: 5px;
    background-color: #e9e9e9;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    padding: 17px 16px 16px;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 10px;
}




.frku-inner-window7 {
    display: flex;
    flex-direction: column;
    position: absolute;
    height: calc(100% - 149px);
    width: calc(100% - 255px);
    top: 142px;
    right: 12px;
    bottom: 7px;
    left: 243px;
    background-color: #fff;
    overflow-y: auto;
    padding: 50px;
    box-sizing: border-box;
}

.frku-fahrer-kursbersicht {
    position: absolute;
    background-color: #e0e0e0;
    width: 100%;
    height: 100vh;
}

@media screen and (max-width: 960px) {
    .frku-modul-list-comp3 {
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        gap: 0;
    }
}

@media screen and (max-width: 420px) {
    .frku-modul-list-comp3 {
        flex-direction: column;
        gap: 10px;
        align-items: flex-start;
        justify-content: flex-start;
    }
}