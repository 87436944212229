.prfungsergebnisse-aller {
    margin: 0;
}

.ape-fahrer,
.ape-text {
    position: relative;
}

.ape-fahrer {
    font-weight: 600;
}


/* Tooltip-Container */
.tooltip {
    position: relative;
    display: inline-block;
}

/* Tooltip-Text */
.tooltip .tooltiptext {
    visibility: hidden;
    width: 80px;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;

    /* Positionierung */
    position: absolute;
    z-index: 1;
    bottom: 100%;
    left: 50%;
    margin-left: -60px;
    /* Verschiebt den Tooltip um die Hälfte seiner Breite nach links */

    /* Übergänge */
    opacity: 0;
    transition: opacity 0.3s;
}

/* Tooltip wird angezeigt beim Hover */
.tooltip:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
}



.ape-fahrer-table {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* Um sicherzustellen, dass sowohl der Text als auch der Button vertikal zentriert sind. */
}

.ape-fahrer-table1 {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    /* Um sicherzustellen, dass sowohl der Text als auch der Button vertikal zentriert sind. */
}

.ape-fahrer-table,
.ape-fahrer-table1 {
    font-weight: 600;
    padding: 5px;
    border-right: 1px solid #000;
    height: "100%";
}

.ape-fahrer-input {
    align-self: stretch;
    border-radius: 5px;
    background-color: #fff;
    border: 1px solid #777;
    flex-direction: row;
    padding: 5px 30px 5px 10px;
}

.ape-date-input,
.ape-date-inputs,
.ape-fahrer-input,
.ape-filter-inputs {
    display: flex;
    align-items: center;
    justify-content: center;
}

.ape-filter-inputs {
    flex-direction: column;
    gap: 10px;
}

.ape-date-input,
.ape-date-inputs {
    flex-direction: row;
}

.ape-date-input {
    flex: 1;
    border-radius: 5px;
    background-color: #fff;
    border: 1px solid #777;
    padding: 5px 10px;
}

.ape-date-inputs {
    align-self: stretch;
    gap: 10px;
}

.ape-checkbox {
    position: relative;
    background-color: #fff;
    border: 1px solid #000;
    box-sizing: border-box;
    width: 20px;
    height: 20px;
    cursor: pointer;
}

.ape-frage-option {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
}

.ape-filter-checkboxen,
.ape-filter-mid {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 30px;
}

.ape-filter-checkboxen {
    align-self: stretch;
    flex-direction: row;
    text-align: left;
    font-size: 14px;
}

.ape-filter-mid {
    flex-direction: column;
}

.ape-filter-btn {
    border-radius: 5px;
    background-color: #3a3a3a;
    border: 1px solid #000;
    padding: 5px 10px;
    align-items: flex-start;
}

.ape-filter-btn,
.ape-filter-buttons,
.ape-filter-reset-btn {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 120px;

}

.ape-filter-reset-btn {
    border-radius: 5px;
    background-color: #d9d9d9;
    border: 1px solid #000;
    padding: 5px 10px;
    align-items: flex-start;
    color: #000;
}

.ape-filter-buttons {
    align-items: flex-end;
    gap: 10px;
    text-align: left;
    font-size: 13px;
    color: #fff;
}

.ape-filter-container,
.ape-upper-section {
    align-self: stretch;
    display: flex;
    flex-direction: row;

    justify-content: space-between;
}

.ape-filter-container {
    flex: 1;
    background-color: #ffc987;
    padding: 10px;
    align-items: center;
    gap: 30px;
    text-align: center;
    font-size: 12px;
}

.ape-upper-section {
    align-items: flex-start;
    gap: 10px;
    font-size: 18px;
}



.ape-stroke {
    position: relative;
    border-right: 2px solid #fff;
    box-sizing: border-box;
    width: 2px;
    height: 40px;
}

.ape-list-component {
    align-self: stretch;
    border-radius: 5px;
    background-color: #fff;
    display: flex;
    flex-direction: row;
    padding: 5px 20px;
    align-items: center;
    justify-content: space-between;
}

.ape-stroke5 {
    position: relative;
    border-right: 2px solid #000;
    box-sizing: border-box;
    width: 2px;
    height: 40px;
}

.ape-hinzufuegen-btn,
.ape-list-component1 {
    align-self: stretch;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.ape-hinzufuegen-btn {
    background-color: #34be39;
    flex-direction: column;
    margin-left: 10px;
    margin-right: 10px;
    color: #fff;
}

.ape-list-component1 {
    background-color: #dedddd;
    flex-direction: row;
    margin-bottom: 10px;
    width: 96%;
    padding: 10px 20px;
    align-items: stretch;
    height: 100%;
}

.ape-hinzufuegen-btn1,
.ape-hinzufuegen-btn3,
.ape-innerwindow {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.ape-hinzufuegen-btn1 {
    align-self: stretch;
    border-radius: 5px;
    background-color: #b52a2a;
    padding: 0 10px;
    margin-left: 10px;
    margin-right: 10px;
    justify-content: space-between;
    color: #fff;
}

.ape-hinzufuegen-btn3 {
    align-self: stretch;
    border-radius: 5px;
    background-color: #6b6b6b;
    padding: 0 10px;
    margin-left: 10px;
    margin-right: 10px;
    justify-content: space-between;
    color: #fff;
}

.ape-innerwindow {
    position: absolute;
    height: calc(100% - 20px);
    width: calc(100% - 267px);
    top: 10px;
    right: 16px;
    bottom: 11px;
    left: 251px;
    border-radius: 4px;
    background-color: #fdfdfd;
    border: 2px solid #777;
    overflow: hidden;
    padding: 50px;
    padding-top: 20px;
    box-sizing: border-box;
    justify-content: flex-start;
    gap: 10px;
}

.ape-inner-window {
    position: absolute;
    height: calc(100% - 20px);
    width: calc(100% - 267px);
    top: 10px;
    right: 16px;
    bottom: 11px;
    left: 251px;
    border-radius: 4px;
    background-color: #fdfdfd;
    border: 2px solid #777;
    box-sizing: border-box;
    overflow-x: auto;
    display: flex;
    flex-direction: column;
    padding: 38px 49px;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 20px;
}

.ape-admin-pruefungsergebnisse {
    position: relative;
    background-color: #e0e0e0;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    text-align: left;
    font-size: 13px;
    color: #000;
    font-family: "Verdana";
}

.ape-text {
    position: relative;
}

.ape-uebersichtsnav-btn-text {
    align-self: stretch;
    position: relative;
    font-size: 20px;
    font-weight: 600;
    font-family: "Verdana";
    color: #fff;
    text-align: center;
}

.ape-uebersichtsnav-btn {
    cursor: pointer;
    border: 1px solid #000;
    padding: 10px 20px;
    background-color: #3a3a3a;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

.ape-ueberschrift {
    position: absolute;
    bottom: 10px;
    left: 30px;
    right: 0px;
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    font-size: 20px;

}

.ape-list-component,
.ape-list-component1 {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    /* Dies teilt den Container in 6 gleiche Teile auf */
    gap: 2px;
    /* Dies ist der Abstand zwischen den Spalten */
}

/* Überschriften in der Mitte */
.ape-list-component>div,
.ape-list-component1>div {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

/* Entfernen der manuellen Striche */
.ape-stroke,
.ape-stroke5 {
    display: none;
}

.ape-pruefungsergebnis {
    border: none;
    background-color: transparent;
    padding-top: 5px;
    padding-bottom: 5px;
    cursor: pointer;
}

.ape-refresh-button {
    flex: 1;
}

.ape-refresh-div {
    flex: 1;
}

.ape-refresh-button:hover {
    flex: 1;
    cursor: pointer;
    /* Zeigt einen Handzeiger, wenn man über den Button fährt. */
}

.ape-common-button:hover {
    cursor: pointer;
    /* Zeigt einen Handzeiger, wenn man über den Button fährt. */
}



.ape-suggestions-container {
    top: 100%;
    /* Dies stellt sicher, dass es direkt unter dem Eingabefeld beginnt */
    border: 1px solid #ccc;
    max-height: 150px;
    overflow-y: auto;
    position: absolute;
    width: 100%;
    z-index: 1000;
}

.ape-suggestion-item {
    padding: 10px;
    cursor: pointer;
    background-color: #eaeaea;
}

.ape-suggestion-item:hover {
    background-color: #cccccc;
}

.ape-input-wrapper {
    display: flex;
    flex-direction: column;
    position: relative;
}

.ape-common-button:hover {
    cursor: pointer;
}