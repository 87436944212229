.akvw-list-component-text {
    position: relative;
  }
  .akvw-list-component-btn-text {
    align-self: stretch;
    position: relative;
    font-size: 16px;
    font-weight: 600;
    font-family: "Arial";
    color: #fff;
    text-align: center;
  }
  .akvw-list-component-btn {
    cursor: pointer;
    border: 0;
    padding: 10px 20px;
    height: 40px;
    background-color: #f39200;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .akvw-list-component-btn:hover {
    background-color: #f90;
  }
  .akvw-list-component {
    display: flex;
    flex-direction: row;
    align-items: center; /* To vertically center the content */
    border-radius: 5px;
    background-color: #e9e9e9;
    padding: 17px 16px 16px;
    justify-content: space-between;
    margin-bottom: 10px; /* Space between module items */
    width: 100%; /* This will stretch the module items fully horizontally */
  }
  @media screen and (max-width: 420px) {
    .akvw-list-component {
      flex-direction: column;
      gap: 10px;
      align-items: flex-start;
      justify-content: flex-start;
    }
  }
  