.menuitem-image-icon {
  position: relative;
  width: 28px;
  height: 32px;
  margin-right: 10px;
  object-fit: cover;
}

.navigation-item,
.navigation-item-active,
.schulungen {
  display: flex;
  align-items: center;
}

.schulungen {
  flex: 1;
  position: relative;
  font-size: 14px;
  font-weight: 600;
  font-family: "Verdana";
  color: #777;
  text-align: left;
  height: 34px;
}

.navigation-item {
  cursor: pointer;
  border: 1px solid #e0e0e0;
  padding: 0 0 0 10px;
  background-color: transparent;
  border-radius: 5px;
  box-sizing: border-box;
  width: 203px;
  height: 62px;
  flex-direction: row;
  justify-content: center;
}


.navigation-item-active {
  cursor: pointer;
  border: 1px solid #e0e0e0;
  padding: 0 0 0 10px;
  background-color: transparent;
  border-radius: 5px;
  box-sizing: border-box;
  width: 203px;
  height: 62px;
  flex-direction: row;
  justify-content: center;
  background-color: #e0e0e0;
  /* Oder jede Farbe/Styles, die Sie für das aktive Element wünschen */
}