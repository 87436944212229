.fput-modulname {
  position: relative;
  display: inline-block;
  width: 400px;
  height: 19px;
  flex-shrink: 0;
}
.fput-administrative-section1,
.fput-modulbox {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}
.fput-modulbox {
  border-radius: 5px;
  background-color: #e9e9e9;
  overflow: hidden;
  padding: 17px 16px 16px;
  justify-content: flex-start;
}
.fput-administrative-section1 {
  align-self: stretch;
  flex-wrap: wrap;
  justify-content: space-between;
}
.fput-teilnehmen3 {
  align-self: stretch;
  position: relative;
  font-size: 20px;
  font-weight: 600;
  font-family: "Verdana";
  color: #fff;
  text-align: center;
}
.fput-inner-window2,
.fput-teilnehmen-btn2 {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
}
.fput-teilnehmen-btn2 {
  cursor: pointer;
  border: 0;
  padding: 3.2247161865234375px 50px;
  background-color: #3a3a3a;
  border-radius: 20px;
}

.fput-button-group {
  display: flex;
  justify-content: flex-end;
  width: 99%;
  bottom: 10px;
  left: 10px;
  padding: 0 15px;
}


.fput-inner-window2 {
  position: absolute;
  height: calc(100% - 20px);
  width: calc(100% - 253px);
  top: 10px;
  right: 10px;
  bottom: 8px;
  left: 243px;
  background-color: #fff;
  border: 2px solid #fff;
  box-sizing: border-box;
  overflow: hidden;
  padding: 10px 15px;
  gap: 20px;
}
.fput-fahrer-pruefungsfrage-text {
  position: relative;
  background-color: #e0e0e0;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  text-align: center;
  font-size: 20px;
  color: #000;
  font-family: "Verdana";
}
@media screen and (max-width: 960px) {
  .fput-modulbox {
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 0;
  }
}
@media screen and (max-width: 420px) {
  .fput-modulbox {
    flex-direction: column;
    gap: 10px;
    align-items: flex-start;
    justify-content: flex-start;
  }
}
