.navigation-links {
  width: 201px;
  height: 500px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 50px;
}
.split-stroke {
  align-self: stretch;
  position: relative;
  border-top: 2px solid #a6a6a6;
  box-sizing: border-box;
  height: 2px;
}
.settings-icon {
  position: relative;
  width: 26px;
  height: 28.95px;
  overflow: hidden;
  flex-shrink: 0;
}
.settings-btn,
.text {
  height: 35.15px;
  display: flex;
  align-items: center;
}
.text {
  position: relative;
  font-size: 14px;
  font-weight: 600;
  font-family: "Verdana";
  color: #fff;
  text-align: left;
  width: 114px;
  flex-shrink: 0;
}
.settings-btn {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: transparent;
  width: 158px;
  flex-direction: row;
  justify-content: flex-start;
  gap: 18px;
}
.logout-icon {
  position: relative;
  width: 28px;
  height: 32.05px;
  overflow: hidden;
  flex-shrink: 0;
}
.logout-btn,
.text1 {
  height: 36.19px;
  display: flex;
  align-items: center;
}
.text1 {
  position: relative;
  font-size: 14px;
  font-weight: 600;
  font-family: "Verdana";
  color: #fff;
  text-align: left;
  width: 112px;
  flex-shrink: 0;
}
.logout-btn {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: transparent;
  width: 158px;
  flex-direction: row;
  justify-content: flex-start;
  gap: 18px;
}
.settings-logout-section,
.sidebar {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.settings-logout-section {
  align-self: stretch;
  height: 92px;
  align-items: flex-start;
}
.sidebar {
  margin: 0;
  position: absolute;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  background-color: #202123;
  align-items: center;
  padding: 26px 17px 50px;
  box-sizing: border-box;
}
