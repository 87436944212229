.rvw-container {
    position: relative;
    background-color: #e0e0e0;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    font-family: "Verdana";
}

.rvw-inner-window {
    background-color: #fff;
    font-family: "Verdana";
    display: flex;
    justify-content: flex-start;
    position: absolute;
    height: calc(100% - 159px);
    width: calc(100% - 258px);
    top: 149px;
    right: 11px;
    bottom: 10px;
    left: 247px;
    box-sizing: border-box;
    overflow: hidden;
    flex-direction: column;
    padding: 0px 30px;
    align-items: center;
}

.rvw-section {
    display: flex;
    font-family: "Verdana";
    flex: 3;
    width: 100%;
    margin-bottom: 10px;
    flex-direction: column;
    gap: 10px;
}

.rvw-section-header {
    font-family: "Verdana";
    display: flex;
    flex: 0.5;
    width: 100%;
    margin-bottom: 10px;
}

.rvw-upper-section{
    font-family: "Verdana";
    display: flex;
    flex: 0.5;
    width: 100%;
    margin-bottom: 10px;
}

.rvw-filter-container{
    font-family: "Verdana";
    display: flex;
    flex: 3;
    padding: 10px;
    background-color: #FFC987;
    margin-bottom: 10px;
    align-items: center;
    justify-content: center;
}

.tooltip {
    visibility: hidden;
    width: 200px;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px;
    position: absolute;
    z-index: 1;
    font-size: 12px;
  }
  
  .icon:hover + .tooltip {
    visibility: visible;
  }
  