.ami-input-frage,
.ami-input-frage1 {
    border-radius: 5px;
    background-color: #fff;
    border: 1px solid #000;
    display: flex;
    flex-direction: column;
    padding: 10px 30px 10px 5px;
    align-items: flex-start;
}

.ami-input-frage {
    box-sizing: border-box;
    width: calc(100% - 150px);
    justify-content: flex-start;
}

.ami-input-frage1 {
    align-self: stretch;
    flex: 1;
    justify-content: center;
    max-height: 40vh;
    /* Sie können diese Höhe je nach Bedarf anpassen */
    overflow-y: scroll;
    max-width: 50vw;
}

.ami-video-icon {
    position: relative;
    width: 36px;
    height: 36px;
    object-fit: cover;
}

.ami-upper-inner-frames,
.ami-video {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
}

.ami-video {
    border: 1px solid #000;
    padding: 30px;
}

.ami-upper-inner-frames {
    align-self: stretch;
    gap: 50px;
}

.ami-picture-icon {
    align-self: stretch;
    flex: 1;
    position: relative;
    max-width: 100%;
    overflow: hidden;
    max-height: 100%;
    object-fit: cover;
}

.ami-inner-section {
    align-self: stretch;
    flex: 1;
    overflow-y: auto;
    background-color: #fff;
    width: calc(100% - 150px);
    max-height: 50vh;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
}

.ami-inner-section {
    height: 100%;
    /* oder setzen Sie die gewünschte Höhe */
    display: flex;
}

.ami-editor {
    flex: 1;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
}

.ami-editor .ql-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    height: 100%;
}

.ami-editor .ql-editor {
    flex: 1;
    height: 100%;
}

.ami-inputitem-comp,
.ami-inputitem-comp1 {
    border: 1px solid #ccc;
    padding: 8px 12px;
    font-size: 14px;
    border-radius: 4px;
    outline: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
    background-color: #f9f9f9;
    width: 100%;
    text-align: left;
}

.ami-inputitem-comp:hover,
.ami-inputitem-comp1:hover {
    background-color: #e6e6e6;
}

.ami-inputitem-comp:focus,
.ami-inputitem-comp1:focus {
    border-color: #007BFF;
}

.ami-inputitem-comp,
.ami-inputitem-comp1,
.ami-mid-section,
.ami-side-buttons {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.ami-mid-section,
.ami-side-buttons {
    align-items: flex-start;
    gap: 30px;
}

.ami-mid-section {
    align-self: stretch;
    flex: 1;
    flex-direction: row;
}

.ami-uebersichtsnav-btn-text {
    align-self: stretch;
    position: relative;
    font-size: 20px;
    font-weight: 600;
    font-family: "Verdana";
    color: #fff;
    text-align: center;
}

.ami-uebersichtsnav-btn {
    cursor: pointer;
    border: 1px solid #000;
    padding: 10px 50px;
    background-color: #3a3a3a;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

.ami-buttons {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.admin-modal-informationsbereic {
    border: #555555 5px solid;
    position: fixed;
    top: 10%;
    left: 10%;
    right: 10%;
    bottom: 10%;
    width: 80vw;
    height: 80vh;
    background: rgb(146, 142, 142);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    display: flex;
    flex-direction: column;
    padding: 50px;
    box-sizing: border-box;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 30px;
    text-align: left;
    font-size: 13px;
    color: #000;
    font-family: "Verdana";
    z-index: 1001;
}